// mini-sab
.mini-sab {
	.container {
		max-width: 1420px;

		.container-holder {
			z-index: 100;
			position: relative;
			min-height: 120px;
			padding: 20px 0;
			background: $white;

			@include media-breakpoint-up(md) {
				margin: 0;
				border-radius: 3px;
				box-shadow: 0 10px 20px rgba($black, 0.1);
			}

			@include media-breakpoint-up(xl) {
				padding: 25px 20px;
			}
		}
	}
}

// home
&.home {
	.mini-sab {
		@include media-breakpoint-down(sm) {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		@include media-breakpoint-up(md) {
			background: $tertiary;

			.container {
				.container-holder {
					margin-top: -60px;
				}
			}
		}
	}
}
