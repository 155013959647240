// menu-overlay
.menu-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	background: $white;
	transition: 0.5s;
	transform: translateY(-100%);
	opacity: 0;
	display: flex;
	flex-direction: column;

	&.show {
		opacity: 1;
		transform: translateY(0);

		@include media-breakpoint-down(sm) {
			.navbar-toggler-label {
				display: none;
			}
		}
	}

	.menu {
		display: block;

		.navbar-nav {
			.nav-item {
				.nav-link {
					padding: 0.15rem 0;

					&:hover {
						color: $primary;
						text-decoration: underline;
					}
				}

				&.active {
					.nav-link {
						text-decoration: underline;
					}
				}

				&.vacancies-menu {
					display: flex;
					align-items: center;

					&:after {
						content: attr(data-vacancies);
						width: 25px;
						margin-left: 7px;
						background: #ef3a36;
						border-radius: 100%;
						color: white;
						font-size: 14px;
						line-height: 25px;
						text-align: center;
						aspect-ratio: 1;
					}
				}
			}
		}
	}

	// menu-overlay-top
	.menu-overlay-top {
		.column {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			min-height: 70px;

			@include media-breakpoint-up(md) {
				min-height: 120px;
			}
		}

		color: $red;

		// menu-toggle
		.menu-toggle {
			color: $red;

			.navbar-toggler {
				.navbar-toggler-icon {
					font-size: 24px;
				}
			}
		}

		// quick-search
		.quick-search {
			ul {
				li {
					a {
						color: inherit;
						text-decoration: none;

						i {
							margin-right: 5px;
						}

						&:hover {
							//color: $red-dark;
							text-decoration: underline;
						}
					}
				}
			}
		}

		// quick-login
		.quick-login {
			@include media-breakpoint-down(sm) {
				display: block;
			}

			ul {
				margin-left: 0;
			}
		}

	}

	// menu-overlay-middle
	.menu-overlay-middle {
		margin-top: 15px;
		margin-bottom: 6vh;

		@include media-breakpoint-up(sm) {
			margin-top: 6vh;
		}

		.column.one {
			@include media-breakpoint-up(lg) {
				flex-flow: wrap;
			}
		}

		// menu-large
		.menu-large {
			flex: 1 1 100%;
			margin-bottom: 30px;

			@include media-breakpoint-up(xl) {
				margin-bottom: 6vh;
			}

			.navbar-nav {
				.nav-item {
					font-size: 32px;

					@include media-breakpoint-up(sm) {
						font-size: clamp(2rem, 8vw, 4rem); // 32px > 64px
					}

					font-weight: 800;
					line-height: 1.25;

					.nav-link {
						&:hover {
							text-decoration-thickness: 4px;
						}
					}

					&.active {
						.nav-link {
							text-decoration-thickness: 4px;
						}
					}
				}
			}
		}

		// menu-small
		.menu-small {
			@include media-breakpoint-up(lg) {
				flex: 1 1 50%;
			}

			.navbar-nav {
				.nav-item {
					.nav-link {
						padding: 0.2rem 0;
					}
				}
			}
		}

		// highlight
		.highlight {
			.card {
				border: none;
				border-radius: 0;
				overflow: visible;

				.card-image {
					margin-bottom: 20px;
				}

				.card-body {
					padding: 0;

					.card-caption {
						color: $red;

						.card-title {
							margin-bottom: 0;
							color: inherit;
							font-size: 24px;
							text-transform: none;
							font-family: $font-family-primary;
						}

						.card-description {
							display: none;
						}

					}

					.card-buttons {
						display: none;
					}
				}
			}
		}
	}

	// menu-overlay-bottom
	.menu-overlay-bottom {
		margin-top: auto;
		margin-bottom: 40px;

		.column {
			@include make-col(6);
		}

		.menu {
			.navbar-nav {
				flex-direction: row;
				flex-wrap: wrap;

				.nav-item {
					margin-right: 40px;
					font-size: 16px;
					font-weight: 400;
				}
			}
		}
	}
}
