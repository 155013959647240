// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 8vh 0;
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 40px 0;

	.title,
	.page-title {
		word-wrap: break-word;

		@media (max-width: 479px) {
			h1 {
				font-size: 40px;
			}
		}
	}

	.menu {
		margin-top: 60px;

		.navbar-nav {
			display: flex;
			flex-flow: row wrap;

			.nav-item {
				margin: 0 10px 10px 0;

				.nav-link {
					@extend .btn;
					border-radius: 2px;
					background: rgba($white, 0.1);
					color: $sand;
					font-size: 16px;
					font-weight: 600;

					@include media-breakpoint-up(xl) {
						font-size: 18px;
						padding: 10px 30px;
					}

					&:hover {
						background: $secondary-dark;
						color: $white;
					}
				}

				&.active {
					.nav-link {
						background: $secondary-dark;
						color: $white;
					}
				}
			}
		}
	}
}

&.vacancy-vacancy-overview {
	.lead-section {
		margin-top: 60px;
	}
}

&.accommodation-category-overview {
	.lead-section {
		margin-top: 8vh;
	}
}

&.accommodation-category-detail {
	.lead-section {
		display: none
	}
}

&.accommodation-detail {
	.lead-section {
		.info {
			.subtitle {
				display: none
			}

			.title {
				h1 {
					@include font-size($h2-font-size);
					font-weight: 800;
					letter-spacing: 0;
					font-family: $font-family-secondary-black;
				}
			}

			// accommodation
			.properties {
				@extend .d-flex;
				@extend .flex-wrap;
				@extend .list-unstyled;
				margin-bottom: 0;

				li {
					display: flex;
					align-items: center;

					+li {
						&::before {
							content: "\f111";
							margin: 0 5px;
							font-family: $font-awesome;
							font-size: 5px;
							font-weight: 900;
							line-height: 1;
						}
					}

					.icon {
						display: none
					}
				}
			}
		}
	}
}

// program-section
// =========================================================================
.program-section {
	@extend .bg-sand;
	padding: 8vh 0;

	// intro
	.intro {
		margin-bottom: 50px;

		h2 {
			margin-bottom: 0;
			//text-transform: uppercase;
		}
	}

	// collection
	.collection {
		margin-bottom: 60px;

		&.slider {
			.item {
				.card {
					display: block;
					border: none;
					border-radius: 0;

					.card-img-overlay {
						padding: 20px;

						@include media-breakpoint-up(xl) {
							padding: 40px;
						}

						background: linear-gradient(180deg, rgba($black, 0.2) 0%, rgba($black, 0.2) 100%);
						border-radius: 0;

						.card-caption {
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
							height: 100%;
							text-shadow: none;

							.card-title {
								margin-bottom: 15px;
								font-size: 16px;

								@include media-breakpoint-up(lg) {
									font-size: 21px;
								}

								font-weight: 700;
								text-transform: none;
								font-family: $font-family-primary;

								&::first-letter {
									text-transform: uppercase;
								}
							}

							.card-subtitle {
								display: flex;
								font-size: 24px;

								@include media-breakpoint-up(lg) {
									font-size: 26px;
								}

								margin-bottom: 0;
								font-weight: 700;
								text-transform: none;
								font-family: $font-family-primary;

								.ticket {
									@include media-breakpoint-down(lg) {
										position: absolute;
										top: 20px;
										left: 20px;
									}

									margin-right: 15px;

									span {
										padding: 3px 10px;
										background: $primary;
										color: $sand;
										font-size: 14px;
										font-weight: 500;

										&:hover {
											background: $primary-dark;
											color: $white;
										}
									}
								}
							}

							.card-description {
								color: rgba($white, 0.6);
								font-size: 16px;

								@include media-breakpoint-up(lg) {
									font-size: 21px;
								}
							}

						}
					}
				}
			}
		}
	}
}

&.event-post-overview {
	.program-section {
		padding: 0 !important;
	}
}

// news-section
// =========================================================================
.news-section {
	margin: 8vh 0;

	// intro
	.intro {
		margin-bottom: 40px;

		h2 {
			margin-bottom: 0;
			//text-transform: uppercase;
		}
	}

	// outro
	.outro {
		margin-top: 6vh;
	}

	.container-two-columns {
		.column {
			&.one {
				@include media-breakpoint-up(xxl) {
					padding-right: 130px !important;
				}

				.item {
					@include media-breakpoint-down(md) {
						margin-bottom: 40px;
					}

					.card {
						.card-image {
							margin-bottom: 20px;

							@include media-breakpoint-up(md) {
								margin-bottom: 40px;
							}
						}
					}
				}
			}

			&.two {
				@include media-breakpoint-up(xxl) {
					padding-left: 20px !important;
				}

				.item {
					@include make-col(12);

					&:first-of-type {
						display: none;
					}

					@include media-breakpoint-down(md) {
						&:nth-child(n+4) {
							display: none;
						}

						@include media-breakpoint-down(md) {
							margin-bottom: 20px;
						}
					}

					.card {
						padding-bottom: 40px;

						@include media-breakpoint-down(md) {
							padding-bottom: 20px;
						}

						border-bottom: 1px solid $red;

						.card-image {
							display: none;
						}
					}

					&:last-of-type {
						.card {
							padding-bottom: 0;
							border-bottom: none;
						}
					}

				}
			}
		}

		// default
		.card {
			border: none;
			border-radius: 0;

			.card-body {
				padding: 0;

				.card-caption {
					color: $red;

					.card-title {
						margin-bottom: 10px;
						color: inherit;
						font-size: 24px;

						@include media-breakpoint-up(xl) {
							font-size: 26px;
						}

						text-transform: none;
						font-family: $font-family-primary;
					}

					.card-subtitle {
						display: none;
					}

					.card-description {
						font-size: 16px;
						font-weight: 400;
						transition: 0.5s;

						@include media-breakpoint-up(xl) {
							font-size: 18px;
						}

						.card-description-content {
							p {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			&:hover {
				.card-body {
					.card-caption {
						color: $black;
					}
				}
			}
		}
	}
}

// featured-section
// =========================================================================
.featured-section {
	background-color: $red-dark;
	color: $sand;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $sand;
	}

	// a
	a:not(.btn) {
		color: $sand;

		&:hover {
			color: $red;
		}
	}

	.container {
		max-width: 1920px;
		padding-left: 0;
		padding-right: 0;

		.container-holder {
			margin: 0;

			.column {
				margin-bottom: 0;
				padding: 0 !important;
				justify-content: center;

				&.one {
					@include media-breakpoint-down(md) {
						order: 1;
					}

					.wysiwyg {
						padding: 45px 20px;

						@include media-breakpoint-up(xl) {
							padding: 15%;
						}

					}
				}

				&.two {
					@include media-breakpoint-down(md) {
						order: 0;
					}

					.wysiwyg {
						padding: 45px 20px;

						@include media-breakpoint-up(xl) {
							padding: 15%;
						}

					}
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.resource-image {
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

// outro-section
// =========================================================================
.outro-section {
	@extend .bg-light;
	padding: 0 !important;

	.container {
		//max-width: 1290px;
		max-width: 1920px;
		padding-left: 0;
		padding-right: 0;

		.container-holder {
			margin: 0;

			.column {
				margin-bottom: 0;
				padding: 0 !important;
				justify-content: center;

				&.two {
					.wysiwyg {
						padding: 45px 20px;

						@include media-breakpoint-up(xl) {
							padding: 15%;
						}

						.link-list {
							margin-top: 6vh;
							max-width: 400px;
						}
					}
				}
			}
		}
	}

	@include media-breakpoint-up(sm) {
		.resource-image {
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

// photos-section
// =========================================================================
.photos-section {
	.container {
		max-width: 1920px;
		padding-left: 0;
		padding-right: 0;

		.container-holder {
			margin: 0;

			.column {
				margin-bottom: 0;
				padding: 0 !important;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
}

// menu-section
// =========================================================================
.menu-section {
	@extend .bg-dark;

	.menu-container {
		max-width: none;
		padding: 0;

		.container-holder {
			margin: 0;

			.column {
				padding: 0;
			}
		}
	}

	// faq
	.faq-category {
		.faq-items {
			.faq-item {
				.faq-answer {

					.wysiwyg,
					.faq-category {

						h2,
						h3,
						h4,
						h5,
						h6,
						li {
							color: $red-dark;
						}

						p:last-of-type,
						ul:last-of-type {
							margin-bottom: 0;
						}

						a {
							color: $red-dark;

							&:hover {
								color: $red;
							}
						}
					}
				}

				&.active,
				&:hover {
					background-color: $white !important;
					color: $red-dark !important;
				}
			}
		}
	}

	/*
	.menu {
		display: block;
		margin-top: 6vh;

		.navbar-nav {
			.nav-item {
				&.dropdown {
					margin-bottom: 30px;

					>.nav-link {
						padding: 0.5rem 20px;

						@include media-breakpoint-up(md) {
							padding-left: 35%;
						}

						color: $red !important;
						font-size: 30px;
						font-weight: 700;

						@include media-breakpoint-up(xl) {
							font-size: 36px;
						}

						text-decoration: none !important;
						pointer-events: none;

						&::after {
							display: none;
						}
					}
				}

				.dropdown-menu {
					display: block;
					background: none;
					box-shadow: none;
					border: none;
					margin: 0;
					padding: 0;
					transform: none !important;

					.nav-item {
						.nav-link {
							padding: 0.25rem 20px;

							@include media-breakpoint-up(md) {
								padding-left: 35%;
							}

							color: $white !important;
							font-size: 19px;

							@include media-breakpoint-up(xl) {
								font-size: 26px;
							}

							font-weight: 700;
							text-decoration: none !important;

							&:hover {
								background: $white;
								color: $red-dark !important;
							}
						}
					}
				}
			}
		}
	}
	*/

}

// content-section
// =========================================================================
.content-section {
	margin: 8vh 0;

	.intro {
		font-size: 19px;

		@include media-breakpoint-up(xl) {
			font-size: 26px;
		}
	}

	.container-one-column {
		.form {
			max-width: 960px;
		}
	}
}

&.blog-post-detail,
&.event-post-detail,
&.accommodation-detail,
&.vacancy-vacancy-detail {
	.content-section {
		.column {
			&.one {
				@include media-breakpoint-up(xxl) {
					padding-right: 130px !important;
				}
			}

			&.two {
				@include media-breakpoint-up(xxl) {
					padding-left: 20px !important;
				}
			}
		}
	}
}

&.accommodation-detail,
&.accommodation-search-book {
	.content-section {
		margin: 40px 0;
	}
}

&.accommodation-detail {
	.content-section {
		.wysiwyg {
			h4 {
				margin-bottom: 1rem;
				color: $black;
				font-size: 24px;
				font-weight: 700;
				text-transform: none;

				@include media-breakpoint-up(xl) {
					font-size: 26px;
				}
			}

			color: rgba($black, 0.8);
			font-size: 18px;
			font-weight: 400;
		}

		.extra-description {
			display: flex;
			flex-direction: column;

			.extra-description-item {
				padding: 1rem 0;
				border-bottom: 1px solid rgba($black, 0.2);

				&:first-of-type {
					padding-top: 0;
				}

				&:last-of-type {
					border-bottom: 0;
				}

				.accommodation-toggle-description-link {
					display: flex;
					align-items: center;
					justify-content: space-between;
					color: $black;
					font-weight: 500;
					text-decoration: none;

					.icon-open,
					.icon-close {
						color: $black;
						transition: 0.5s;
					}

					&:hover {

						.icon-open,
						.icon-close {
							color: $red;
						}
					}
				}

				.accommodation-toggle-description-content {
					margin-top: 1rem;
				}
			}
		}
	}
}

&.vacancy-vacancy-detail {
	.content-section {
		@include media-breakpoint-up(xl) {
			margin: 150px 0;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	margin: 8vh 0;

	.resource-image {
		img {
			height: 50vh;
			min-height: 320px;
			max-height: 640px;
			object-fit: cover;
		}
	}
}

&.werken-bij-cultuur-page {
	.banner-section {
		@include media-breakpoint-up(xl) {
			margin: 150px 0;
		}
	}
}

// faq-section
// =========================================================================
.faq-section {
	margin: 8vh 0;
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 8vh 0;

	// other-service-categories
	.other-service-categories {
		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
					margin-bottom: 0;

					&:first-of-type {
						.card {
							.card-body {
								.card-caption {
									.card-title-link {
										.card-title {
											padding-top: 0;
										}
									}
								}
							}
						}
					}

					&:last-of-type {
						.card {
							border-bottom: 0;
						}
					}

					.card {
						background: none;
						border: none;
						border-radius: 0;
						border-bottom: 1px solid $white;

						&.highlight {
							background: $tertiary;
							border-bottom: 0;

							.card-body {
								padding: 20px;

								@include media-breakpoint-up(xl) {
									padding: 40px;
								}

								.card-caption {
									color: $secondary;

									.card-title-link {
										margin-bottom: 1rem;

										.card-title {
											padding: 0;

											&:hover {
												color: $black;
											}
										}

									}

									.card-description {
										display: block;
									}
								}
							}
						}

						.card-image {
							display: none;
						}

						.card-body {
							padding: 0;

							.card-caption {
								color: $white;

								.card-title-link {
									margin-bottom: 0;

									.card-title {
										position: relative;
										display: flex;
										align-items: center;
										padding: 2rem 0;
										font-size: clamp(1.625rem, 2.5vw, 2.25rem); // 26px > 36px
										text-transform: none;
										font-family: $font-family-primary;

										&::after {
											content: '\f054';
											font-family: $font-awesome;
											margin-left: auto;
											color: inherit;
											font-size: 18px;
											font-weight: 400;
										}

										&:hover {
											color: $tertiary;
										}
									}
								}

								.card-subtitle {
									display: none;
								}

								.card-description {
									display: none;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

&.service-overview,
&.service-category-detail,
&.blog-post-overview,
&.vacancy-vacancy-overview,
&.werken-bij-category-page {
	.bundle-overview-section {
		.column {
			&.one {
				@include media-breakpoint-up(xxl) {
					padding-right: 130px !important;
				}
			}

			&.two {
				@include media-breakpoint-up(xxl) {
					padding-left: 20px !important;
				}
			}
		}
	}
}

&.vacancy-vacancy-overview {
	.bundle-overview-section {
		@include media-breakpoint-up(xl) {
			margin: 150px 0;
		}

		.wysiwyg {
			h2 {
				@include media-breakpoint-up(xl) {
					margin-bottom: 50px;
				}
			}
		}
	}
}

&.werken-bij-category-page {
	.bundle-overview-section {
		@include media-breakpoint-up(xl) {
			margin: 150px 0;
		}
	}
}

&.service-overview,
&.vacancy-vacancy-overview,
&.werken-bij-category-page {
	.bundle-overview-section {
		.column {
			&.one {

				.resource-image {
					margin-bottom: 40px;
				}

				.wysiwyg {
					font-size: 24px;
					font-weight: 700;

					@include media-breakpoint-up(xl) {
						font-size: 26px;
					}
				}
			}

			&.two {
				.item {
					@include make-col(12);

					&:not(:has(.card.highlight)) {
						margin-bottom: 0;
					}

					&:first-of-type {
						.card {
							.card-body {
								.card-caption {
									.card-title-link {
										.card-title {
											padding-top: 0;
										}
									}
								}
							}
						}
					}

					&:last-of-type {
						.card {
							border-bottom: 0;
						}
					}

					.card {
						background: none;
						border: none;
						border-radius: 0;
						border-bottom: 1px solid $white;

						&.highlight {
							background: $tertiary;
							border-bottom: 0;

							.card-body {
								padding: 20px;

								@include media-breakpoint-up(xl) {
									padding: 40px;
								}

								.card-caption {
									color: $secondary;

									.card-title-link {
										margin-bottom: 1rem;

										.card-title {
											padding: 0;
											line-height: $headings-line-height;
											//font-weight: 700;

											&:hover {
												color: $black;
											}
										}

									}

									.card-description {
										display: block;

										p {
											margin-bottom: 0;
										}
									}
								}
							}
						}

						.card-image {
							display: none;
						}

						.card-body {
							padding: 0;

							.card-caption {
								color: $white;

								.card-title-link {
									margin-bottom: 0;

									.card-title {
										position: relative;
										display: flex;
										align-items: center;
										padding: 2rem 0;
										font-size: clamp(1.625rem, 2.5vw, 2.25rem); // 26px > 36px
										text-transform: none;
										font-family: $font-family-primary;

										&::after {
											content: '\f054';
											font-family: $font-awesome;
											margin-left: auto;
											color: inherit;
											font-size: 18px;
											font-weight: 400;
										}

										&:hover {
											color: $tertiary;
										}
									}
								}

								.card-subtitle {
									display: none;
								}

								.card-description {
									display: none;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

&.blog-post-overview {
	.bundle-overview-section {
		.column {

			// default
			.card {
				border: none;
				border-radius: 0;

				.card-image {
					margin-bottom: 40px;
				}

				.card-body {
					padding: 0;

					.card-caption {
						color: $red;

						.card-title {
							margin-bottom: 10px;
							color: inherit;
							font-size: 24px;

							@include media-breakpoint-up(xl) {
								font-size: 26px;
							}

							text-transform: none;
							font-family: $font-family-primary;
						}

						.card-subtitle {
							display: none;
						}

						.card-description {
							font-size: 16px;
							font-weight: 400;
							transition: 0.5s;

							@include media-breakpoint-up(xl) {
								font-size: 18px;
							}
						}
					}
				}

				&:hover {
					.card-body {
						.card-caption {
							color: $black;
						}
					}
				}
			}

			&.two {
				.item {
					@include make-col(12);

					&:first-of-type {
						display: none;
					}

					.card {
						padding-bottom: 40px;
						border-bottom: 1px solid $red;

						.card-image {
							display: none;
						}

						.card-caption {
							.card-description {
								.card-description-content {
									p {
										margin-bottom: 0;
									}
								}
							}
						}
					}

					&:last-of-type {
						.card {
							padding-bottom: 0;
							border-bottom: none;
						}
					}
				}
			}
		}
	}
}

&.accommodation-category-overview {
	.bundle-overview-section {

		// collection
		.collection {
			.card {
				display: block;
				max-height: 75vh;
				border: none;
				border-radius: 5px;

				.card-img-overlay {
					padding: 20px;
					background: linear-gradient(180deg, rgba($black, 0) 62.22%, rgba($black, 0.7) 100%);
					border-radius: 0;
					transition: 0.5s;

					.card-caption {
						display: flex;
						flex-direction: column;
						height: 100%;
						text-shadow: none;

						.card-subtitle {
							order: 0;
							margin: 0 auto auto 0;
							padding: 5px 10px;
							border-radius: 30px;
							background: #fff;
							color: #000;
							font-size: 14px;
							font-weight: 500;
							line-height: 25px;
							font-family: $font-family-primary;
							text-transform: none;
						}

						.card-title {
							order: 1;
							margin: auto 0 5px 0;
							font-size: 24px;

							@include media-breakpoint-up(xl) {
								font-size: 26px;
							}

							font-weight: 700;
							font-family: $font-family-primary;
							text-transform: none;
						}

						.card-description {
							order: 3;
							font-size: 18px;
							font-weight: 600;
						}
					}
				}

				&:hover {
					.card-img-overlay {
						background: linear-gradient(180deg, rgba($black, 0.1) 62.22%, rgba($black, 0.8) 100%);
					}
				}
			}
		}
	}
}

&.accommodation-category-detail {
	.bundle-overview-section {

		// collection
		.collection {
			.card {
				display: block;
				border: none;
				border-radius: 0;

				.card-image {
					border-radius: 10px;
				}

				.card-body {
					margin-top: 20px;
					padding: 0;

					.card-caption {
						.card-title {
							font-size: 21px;
							font-weight: 700;
							font-family: $font-family-primary;
						}

						.card-subtitle {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							margin: 20px 20px 0 20px;
							padding: 9px 10px;
							border-radius: 30px;
							background: #fff;
							color: #000;
							font-size: 14px;
							font-weight: 500;
							font-family: $font-family-primary;
							text-transform: none;
						}

						.card-description {
							font-size: 16px;
							font-weight: 400;
							color: rgba($black, 0.7);

							.list-icons {
								display: flex;
								flex-wrap: wrap;
								align-items: center;

								.list-item {
									display: flex;
									align-items: center;
									padding-left: 0;

									+li {
										&::before {
											content: "\f111";
											margin: 0 5px;
											font-family: $font-awesome;
											font-size: 5px;
											font-weight: 900;
											line-height: 1;
										}
									}

									.icon {
										display: none;
									}
								}
							}
						}
					}

					.card-buttons {
						margin-top: 0;

						.card-btn {
							padding: 0;
							background: none;
							border: none;
							color: $black;
							font-size: 14px;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
}

&.event-post-overview {
	.bundle-overview-section {
		margin-top: 10px;
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 40px 0 8vh 0;

	.back-to-overview-link {
		margin-top: 2rem;
	}
}

&.blog-post-detail,
&.event-post-detail,
&.vacancy-vacancy-detail,
&.service-category-detail {
	.bundle-detail-section {
		.column {
			&.one {
				@include media-breakpoint-up(xxl) {
					padding-right: 130px !important;
				}

				// wysiwyg
				.wysiwyg {
					margin-top: 40px;
					font-size: 24px;
					font-weight: 700;

					@include media-breakpoint-up(xl) {
						font-size: 26px;
					}
				}
			}

			&.two {
				@include media-breakpoint-up(xxl) {
					padding-left: 20px !important;
				}
			}
		}
	}
}

&.vacancy-vacancy-detail {
	.bundle-detail-section {
		margin-bottom: 0;

		.back-to-overview-link {
			margin-bottom: 3rem;
		}
	}
}

&.vacancy-vacancy-detail,
&.service-category-detail {
	.bundle-detail-section {
		.column {

			&.two {

				// custom-back-to-overview
				.custom-back-to-overview {
					margin-bottom: 40px;
					font-size: 24px;
					line-height: 1.2;
					font-weight: 700;

					@include media-breakpoint-up(xl) {
						font-size: 36px;
					}

					a {
						color: $tertiary !important;
						text-decoration: none;

						&:hover {
							color: $white !important;
						}

						&::after {
							content: '\f061';
							font-family: $font-awesome;
							margin: 0 10px 0 5px;
							color: inherit;
							font-weight: 900;
						}
					}
				}
			}
		}
	}
}

&.blog-post-detail,
&.event-post-detail {
	.bundle-detail-section {
		.column {
			&.one {

				// ticket
				.ticket {
					padding: 20px;

					@include media-breakpoint-up(xl) {
						padding: 40px;
					}

					background: $primary-dark;
					color: $white;

					.row {
						align-items: flex-end;
					}

					.ticket-name {
						font-size: 21px;
						font-weight: 700;
					}

					.ticket-subtitle {
						font-size: 16px;
						font-weight: 400;
					}

					.ticket-price {
						font-size: 24px;
						font-weight: 700;

						@include media-breakpoint-up(xl) {
							font-size: 36px;
						}

						text-align: right;
					}

					.ticket-button {
						margin-top: 20px;

						a {
							@extend .btn-primary;
							padding: 15px;
							font-size: 18px;
							font-weight: 600;
						}
					}
				}
			}

			&.two {

				// subtitle
				.subtitle {
					h4 {
						font-size: 26px;
						font-family: $font-family-primary;
						text-transform: none;
					}

					h5 {
						margin-bottom: 1rem;
						color: $black;
						font-size: 16px;
						font-weight: 400;
						font-family: $font-family-primary;
						text-transform: none;
					}
				}

				// date
				.date {
					margin: 40px 0;
					padding: 20px 0;
					border-top: 1px solid $primary-dark;
					border-bottom: 1px solid $primary-dark;
					font-weight: 700;

					.date-location {
						margin-bottom: 5px;

						span {
							//color: $red;
						}
					}
				}

				// title
				.title {
					h1 {
						margin-bottom: 1.5rem;
						font-size: 36px;
						font-weight: 700;
						font-family: $font-family-primary;
						text-transform: none;
					}
				}

				// wysiwyg
				.title+.wysiwyg {
					margin-top: -0.5rem;
					font-size: 26px;
					font-weight: 700;
				}
			}
		}
	}

	// other-blog-items
	.other-blog-items {
		margin-top: 40px;

		&.grid {
			.grid-items {
				.item {
					@include make-col(12);
				}
			}
		}

		.item {
			&:last-of-type {
				.card {
					border-bottom: none;
				}
			}

			.card {
				border: none;
				border-radius: 0;
				padding-bottom: 40px;
				border-bottom: 1px solid $red;

				.card-image {
					display: none;
				}

				.card-body {
					padding: 0;

					.card-caption {
						color: $red;

						.card-title {
							margin-bottom: 10px;
							color: inherit;
							font-size: 24px;

							@include media-breakpoint-up(xl) {
								font-size: 26px;
							}

							text-transform: none;
							font-family: $font-family-primary;
						}

						.card-subtitle {
							display: none;
						}

						.card-description {
							font-size: 16px;
							font-weight: 400;
							transition: 0.5s;

							@include media-breakpoint-up(xl) {
								font-size: 18px;
							}

							p {
								margin-bottom: 0;
							}
						}
					}
				}

				&:hover {
					.card-body {
						.card-caption {
							color: $black;
						}
					}
				}
			}
		}
	}
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 40px 0;

	.gallery-horizontal {
		@include media-breakpoint-up(sm) {
			margin: 0 -5px;
		}

		.gallery-item {
			margin: 0 0 10px 0;

			@include media-breakpoint-up(sm) {
				padding: 0 5px;
			}

			&.large-item {
				@include media-breakpoint-up(lg) {
					@include make-col(7);
				}
			}
		}
	}

	.gallery-thumbs {
		@include media-breakpoint-up(lg) {
			@include make-col(5);
		}

		.gallery-item {
			@include media-breakpoint-up(lg) {
				@include make-col(12);
			}

			overflow: hidden;

			.gallery-caption {
				top: auto;
				left: auto;
				right: 20px;
				bottom: 20px;
				padding: 5px 20px;
				border-radius: 3px;
				background: #fff;
				color: #000;
				font-size: 14px;
				font-weight: 500;
				line-height: 25px;
				font-family: $font-family-primary;
				text-transform: none;
				transition: 0.5s;

				&::before {
					display: none;
				}

				&:hover {
					background: $primary-dark;
					color: $white;
				}
			}

			.gallery-img {
				object-fit: cover;
			}

			@include media-breakpoint-only(lg) {
				.gallery-img {
					height: 261px;
				}
			}

			@include media-breakpoint-only(xl) {
				.gallery-img {
					height: 313px;
				}
			}

			@include media-breakpoint-only(xxl) {
				.gallery-img {
					height: 372px;
				}
			}

		}
	}
}

// booking-section
// =========================================================================
.booking-section {
	margin: 8vh 0;

	.heading {
		display: none;
	}

	#TommyBookingSupport {
		padding-top: 20px;
		border-top: 1px solid rgba($black, 0.2);
	}

	.tommy {
		margin-bottom: 40px;
		padding-top: 20px;
		border-top: 1px solid rgba($black, 0.2);
	}
}

// mobile-intro-section
// =========================================================================
.mobile-intro-section {
	@include media-breakpoint-up(md) {
		display: none;
	}

	margin-top: 20px;

	.title {
		h1 {
			color: $primary-dark;
			font-size: 48px;
		}

		&:first-of-type {
			h1 {
				color: $primary;
			}
		}
	}

	.wysiwyg {
		margin-top: 1rem;
		font-weight: 400;

		p {
			margin-bottom: 0;
		}
	}
}

// mobile-banner-section
// =========================================================================
.mobile-banner-section {
	@include media-breakpoint-up(md) {
		display: none;
	}

	padding: 40px 0 8vh 0;
	background: $primary;

	.title {
		h2 {
			margin-bottom: 0;
			line-height: 1;
			color: $tertiary;
			font-size: 40px;
			font-weight: 400;
			letter-spacing: -1px;
			text-transform: uppercase;
			font-family: $font-family-tertiary;
		}

		&:first-of-type {
			h2 {
				color: $white;
			}
		}
	}

	.owl-stage-outer,
	.owl-stage-outer .item {
		height: 50vh;
		min-height: 460px;
		max-height: 900px;
	}

	.owl-carousel {
		margin-top: 40px;

		.owl-item {
			border-radius: 5px;
			overflow: hidden;
		}

		.item {
			&::before {
				content: "";
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.1);
			}

			&::after {
				content: "";
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.7) 100%);
			}

			.owl-caption-holder {
				z-index: 3;

				.owl-container {
					.owl-caption {
						text-align: left;
						text-shadow: none;

						.owl-title,
						.owl-subtitle {
							margin-bottom: 0;
							line-height: 1;
							font-size: 36px;
							font-weight: 400;
							letter-spacing: -1px;
							text-transform: uppercase;
							font-family: $font-family-tertiary;
						}

						.owl-title {
							color: $white;
						}

						.owl-subtitle {
							color: $sand;
						}

						.owl-btn {
							display: inline-flex;
							align-items: center;
							margin-top: 1rem;
							padding: 0;
							background: none;
							border-radius: 0;
							border: none;
							border-bottom: 1px solid $white;
							color: $white;
							outline: none;

							&:hover {
								color: $red;
								border-color: $red;
							}
						}
					}
				}
			}
		}

		// owl-dots
		.owl-dots {
			position: static;
			display: flex;
			flex: 1;
			height: 2px;
			margin-top: 40px;
			padding: 0;
			border-radius: 2px;
			background: rgba($black, 0.2);

			.owl-dot {
				height: 2px;
				border-radius: 2px;
				flex: 1;
				transition: 0.5s;

				&:hover {
					background: rgba($white, 0.5);
				}

				&.active {
					background: $white;
				}

				span {
					display: none;
				}
			}
		}
	}
}
