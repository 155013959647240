&.faq-page,
&.faq-overview,
&.de-bolder {

	.faq-section,
	.bundle-overview-section {
		.container {
			max-width: none;
			padding: 0;

			.container-holder {
				margin: 0;

				.column {
					padding: 0;
				}
			}
		}
	}

	// faq-overview
	.faq-overview {
		>.faq-category {
			margin-bottom: 6vh;
		}

		.faq-category {
			.faq-title {
				margin-bottom: 20px;
				padding: 0 20px;

				@include media-breakpoint-up(md) {
					padding-left: 35%;
				}

				.title {
					h2 {
						margin-bottom: 0;
						color: $green-dark;
						font-size: 30px;

						@include media-breakpoint-up(xl) {
							font-size: 36px;
						}

						text-transform: none;
						font-family: $font-family-primary;
					}
				}

				.faq-icon {
					display: none !important;
				}
			}

			.faq-items {
				display: block !important;

				.faq-item {
					padding: 0.75rem 0;
					color: $white;
					transition: 0.5s;

					.faq-question {
						padding: 0 20px;

						@include media-breakpoint-up(md) {
							padding-left: 35%;
						}

						.title,
						.faq-category {
							h3 {
								margin-bottom: 0;
								color: inherit;
								font-size: 19px;

								@include media-breakpoint-up(xl) {
									font-size: 26px;
								}

								text-transform: none;
								font-family: $font-family-primary;
							}
						}

						.faq-icon {
							display: none !important;
						}

					}

					.faq-answer {
						display: none;
						padding: 0 20px;

						@include media-breakpoint-up(md) {
							padding-left: 35%;
						}

						.wysiwyg,
						.faq-category {
							max-width: 640px;

							h2,
							h3,
							h4,
							h5,
							h6,
							li {
								color: $green;
							}

							p:last-of-type,
							ul:last-of-type {
								margin-bottom: 0;
							}

							a {
								color: $green;
								text-decoration: underline;

								&:hover {
									color: $green;
									text-decoration: none;
								}
							}
						}
					}

					&.active {
						padding: 1.5rem 0;
					}

					&.active,
					&:hover {
						background-color: $sand;
						color: $green;
					}

					&:hover {
						cursor: pointer;
					}

					&.active {
						.faq-answer {
							margin-top: 1rem;
						}
					}
				}
			}
		}
	}
}
