// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$red: #ef3a36;
$red-dark: #820725;
$green: #3d6f3d;
$green-dark: #153415;
$sand: #fff7db;
$white: #fff;
$black: #000;

// theme-colors
$primary: $red;
$primary-dark: $red-dark;
$secondary: $green;
$secondary-dark: $green-dark;
$tertiary: $sand;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $red;
$link-hover-color: $black;
$link-decoration: underline;

// typography
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
	font-family: 'Aachen Bold';
	src: local('Aachen Bold'), url('/webfonts/aachen-bold-opentype.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Tofino Regular';
	src: url('/webfonts/tofino-regularpersonal-webfont.woff2') format('woff2'),
		url('/webfonts/tofino-regularpersonal-webfont.woff') format('woff'),
		url('/webfonts/tofino-regularpersonal-webfont.otf') format('opentype'),
		url('/webfonts/tofino-regularpersonal-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Tofino Bold';
	src: url('/webfonts/tofino-boldpersonal-webfont.woff2') format('woff2'),
		url('/webfonts/tofino-boldpersonal-webfont.woff') format('woff'),
		url('/webfonts/tofino-boldpersonal-webfont.otf') format('opentype'),
		url('/webfonts/tofino-boldpersonal-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Tofino Black';
	src: url('/webfonts/tofino-blackpersonal-webfont.woff2') format('woff2'),
		url('/webfonts/tofino-blackpersonal-webfont.woff') format('woff'),
		url('/webfonts/tofino-blackpersonal-webfont.otf') format('opentype'),
		url('/webfonts/tofino-blackpersonal-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

$font-family-primary: 'Inter', sans-serif;
$font-family-secondary-bold: 'Tofino Bold';
$font-family-secondary-black: 'Tofino Black';
$font-family-tertiary: 'Aachen Bold';

$font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$font-weight-base: 400;
$line-height-base: 1.5;
$paragraph-margin-bottom: 2rem;

$h1-font-size: $font-size-base * 5.25; // 84px
$h2-font-size: $font-size-base * 4; // 64px
$h3-font-size: $font-size-base * 2.75; // 44px
$h4-font-size: $font-size-base * 1.3125; // 21px
$h5-font-size: $font-size-base * 1.1875; // 19px
$h6-font-size: $font-size-base; // 16px

$headings-margin-bottom: 2rem;
$headings-font-family: $font-family-secondary-bold;
$headings-font-weight: 700;
$headings-line-height: 1.189189189;
$headings-color: $red;

// contextual
$light: $red;
$dark: $red-dark;
$text-light: $sand;
$text-dark: $white;

// shadow
$shadow: 0 3px 6px rgba($black, 0.15);

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;
*/

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

// grid containers
$container-max-widths: (
  lg: 960px,
  xl: 1140px,
  xxl: 1340px
);

// grid columns
$grid-gutter-width: 40px;
