// events
.events {

	// events-nav
	.events-nav {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		.nav {
			&.nav-categories {
				@include media-breakpoint-up(xl) {
					flex: 1;
				}

				.nav-item {
					margin: 0 10px 10px 0;

					.nav-link {
						@extend .btn;
						border-radius: 2px;
						background: rgba($primary-dark, 0.1);
						color: rgba($primary-dark, 0.5);
						font-size: 16px;
						font-weight: 600;

						@include media-breakpoint-up(xl) {
							font-size: 18px;
							padding: 10px 30px;
						}

						&:hover {
							color: $primary-dark;
						}

						&.active {
							background: $primary-dark;
							color: $white;
						}
					}
				}
			}

			&.nav-pager {
				font-weight: 600;

				@include media-breakpoint-up(xl) {
					font-size: 18px;
				}

				.nav-link {
					color: $primary-dark;

					&.prev {
						padding-left: 0;

						&::before {
							content: '\f053';
							font-family: $font-awesome;
							margin-right: 10px;
							color: inherit;
							font-size: 18px;
							font-weight: 400;
						}
					}

					&.next {
						padding-right: 0;

						&::after {
							content: '\f054';
							font-family: $font-awesome;
							margin-left: 10px;
							color: inherit;
							font-size: 18px;
							font-weight: 400;
						}
					}

					&:hover {
						color: $primary;
					}
				}
			}
		}
	}

	// events-overview
	.events-overview {
		margin: 40px 0;

		hr {
			margin: 5px 0;
			border-color: $primary-dark;

			&:last-of-type {
				display: none;
			}
		}

		.no-results {
			padding: 10px 30px;
			border-radius: 2px;
			background: rgba($primary-dark, 0.1);
			color: rgba($primary-dark, 0.5);
			font-size: 18px;
			font-weight: 600;
		}

		.row-day {

			.date {
				padding-top: 15px;
				margin-bottom: 5px;
				color: rgba($primary-dark, 0.5);
				font-size: 16px;
				font-weight: 700;
				line-height: 1;

				&:first-letter {
					text-transform: uppercase;
				}

				@include media-breakpoint-up(xl) {
					font-size: 26px;
				}
			}

			.row-event {
				padding-top: 15px;
				padding-bottom: 15px;
				justify-content: space-between;
				text-decoration: none;

				a {
					text-decoration: none;
				}

				font-size: 16px;
				font-weight: 400;

				@include media-breakpoint-up(xl) {
					font-size: 18px;
				}

				&:hover {
					background: rgba($primary-dark, 0.1);
				}

				.name {
					margin-bottom: 5px;
					font-size: 24px;
					font-weight: 700;
					line-height: 1;

					@include media-breakpoint-up(xl) {
						font-size: 26px;
					}
				}

				.ticket {
					@include media-breakpoint-down(md) {
						text-align: right;
					}

					.btn {
						padding: 3px 10px;
						background: $primary;
						color: $sand;
						font-size: 14px;
						font-weight: 500;

						&:hover {
							background: $primary-dark;
						}
					}
				}

				.time {
					@include media-breakpoint-up(md) {
						text-align: right;
					}
				}
			}

		}
	}

	// events-page
	.events-page {
		margin-top: 45px;

		a {
			text-decoration: underline;
		}
	}
}
