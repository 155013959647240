// btn
.btn {
	font-weight: 700;

	// btn-primary
	&.btn-primary {
		@include media-breakpoint-up(xl) {
			min-width: 300px;
		}
		padding: 15px;

		&:hover {
			background-color: $primary-dark;
			border-color: $primary-dark;
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {}
	}

	// btn-secondary
	&.btn-secondary {
		@include media-breakpoint-up(xl) {
			min-width: 300px;
		}
		padding: 15px;

		&:hover {}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-primary;
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
