// red-dark
&.w3-body-color-red-dark {
	background: $red-dark;

	// header
	.header {
		color: $white;

		.logo {
			&.white {
				display: block;
			}

			&.red {
				display: none;
			}
		}
	}

	// main
	.main {
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li {
			color: $white;
		}

		// a
		a:not(.btn):not(.card):not(.card-title-link):not(.card-btn):not(.nav-link) {
			color: $white;

			&:hover {
				color: lighten($text-dark, 15%);
			}
		}

		// custom-list
		.custom-list,
		.custom-list-extra {
			li {
				&::before {
					color: $white;
				}
			}
		}
	}

	// lead-section
	.lead-section {
		.title:first-of-type h1 {
			color: $sand;
		}

		.title:last-of-type h1 {
			color: $white;
		}
	}

	// footer
	.footer {
		background-color: $red-dark;

		@include media-breakpoint-up(md) {
			background-color: $red-dark;
		}

		.footer-outro {
			@include media-breakpoint-down(sm) {
				background-color: $red-dark;
			}
		}
	}
}

// green
&.w3-body-color-green {
	background: $green;

	// header
	.header {
		color: $white;

		.logo {
			&.white {
				display: block;
			}

			&.red {
				display: none;
			}
		}
	}

	// main
	.main {
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li {
			color: $white;
		}

		h2,
		h3 {
			color: $sand;
		}

		// a
		a:not(.btn):not(.card):not(.card-title-link):not(.card-btn):not(.nav-link) {
			color: $white;

			&:hover {
				color: $sand;
			}
		}

		// custom-list
		.custom-list,
		.custom-list-extra {
			li {
				&::before {
					color: $white;
				}
			}
		}

		.btn-primary,
		.btn-secondary {
			background-color: $sand;
			border-color: $sand;
			color: $green;

			&:hover,
			&:focus {
				background-color: $white;
				border-color: $white;
				color: $black;
			}
		}
	}

	// lead-section
	.lead-section {
		.title:first-of-type h1 {
			color: $white;
		}

		.title:last-of-type h1 {
			color: $sand;
		}
	}

	// featured-section
	.featured-section {
		background-color: $green-dark;
		color: $sand;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li {
			color: $sand;
		}

		// a
		a:not(.btn) {
			color: $sand !important;

			&:hover {
				color: $white !important;
			}
		}
	}

	// footer
	.footer {
		@include media-breakpoint-up(md) {
			background-image: url('/images/footer-bg-green.svg');
			background-color: $green;
		}

		.footer-outro {
			@include media-breakpoint-down(sm) {
				background-image: url('/images/footer-bg-green.svg');
				background-color: $green;
			}
		}

		.footer-sitelinks {
			.footer-link {
				.list {
					.list-item {
						a {
							@include media-breakpoint-down(sm) {
								color: $green;
							}
						}
					}
				}
			}
		}
	}
}

// sand
&.w3-body-color-sand {
	background: $sand;

	// header
	.header {
		color: $red-dark;

		.logo {
			&.white {
				display: none;
			}

			&.red {
				display: none;
			}

			&.red-dark {
				display: block;
			}
		}

		&.sticky {
			.logo {
				&.red-dark {
					display: none;
				}
			}
		}
	}

	// main
	.main {
		color: $red-dark;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li {
			color: $red-dark;
		}

		// a
		a:not(.btn):not(.card):not(.card-title-link):not(.card-btn):not(.nav-link) {
			color: $red-dark;

			&:hover {
				color: $red;
			}
		}

		// custom-list
		.custom-list,
		.custom-list-extra {
			li {
				&::before {
					color: $red-dark;
				}
			}
		}

		// link-list
		.link-list {
			li {
				border-bottom-color: $red-dark;
			}
		}
	}

	// lead-section
	.lead-section {
		.title {
			h1 {
				color: $red-dark;
			}

			&:last-of-type {
				h1 {
					color: #a71438;
				}
			}
		}
	}

	// menu-section
	.menu-section {
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $red;
		}
	}

	// featured-section
	.featured-section {
		background-color: $red-dark;
		color: $sand;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li {
			color: $sand;
		}

		// a
		a:not(.btn) {
			color: $sand;

			&:hover {
				color: $red;
			}
		}
	}
}
