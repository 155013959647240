// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	color: $red;
	font-weight: 500;
	transition: background-color 0.5s, box-shadow 0.5s;

	&.sticky {
		background: $white;
		box-shadow: 0 10px 20px rgba($black, 0.1);
		color: $red;

		.logo {
			&.white {
				display: none;
			}

			&.red {
				display: block;
			}
		}
	}

	.header-top {
		.container {
			.container-holder {
				>.column {
					@include media-breakpoint-down(sm) {
						justify-content: space-between !important;
					}

					@include media-breakpoint-up(md) {
						min-height: 120px;
					}

					@extend .align-items-center;
					@extend .justify-content-center;

					@include media-breakpoint-down(lg) {
						flex-flow: row wrap;
					}

					@extend .navbar-expand-xl;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(sm) {
			order: 1;
		}

		.navbar-toggler {
			display: flex !important;
			align-items: center;

			@include media-breakpoint-down(sm) {
				flex-direction: row-reverse;
			}

			padding: 0;
			border-radius: 0;
			border: 0;
			border-bottom: 2px solid transparent;
			color: inherit;
			font-size: inherit;
			font-weight: 500;
			line-height: 25px;
			transition: color 0.5s;

			.navbar-toggler-label {
				margin-left: 10px;

				@include media-breakpoint-down(sm) {
					margin-left: 0;
					margin-right: 10px;
				}

				font-size: inherit;
			}

			&:hover {
				border-bottom-color: currentColor;
			}
		}
	}

	// quick-menu
	.quick-menu {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		ul {
			li {
				margin-left: 30px;

				@include media-breakpoint-up(lg) {
					margin-left: 80px;
				}

				a {
					border-bottom: 2px solid transparent;
					color: inherit;
					text-decoration: none;
					transition: color 0.5s;

					&:hover {
						border-bottom-color: currentColor;
					}
				}
			}
		}
	}

	// logo
	.logo {
		@include media-breakpoint-down(sm) {
			max-width: 170px;
			margin: 15px 15px 15px 0;
		}

		@include media-breakpoint-up(md) {
			max-width: 200px;
			margin: -30px auto 0 auto;
		}

		@include media-breakpoint-up(xl) {
			margin-left: 165px;
		}

		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}

		&.white {
			display: none;
		}

		&.red {
			display: block;
		}

		&.red-dark {
			display: none;
		}
	}

	// quick-login
	.quick-login {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		ul {
			margin-left: 30px;

			@include media-breakpoint-up(lg) {
				margin-left: 80px;
			}

			font-weight: 400;

			li {
				a {
					border-bottom: 1px solid transparent;
					color: inherit;
					text-decoration: none;
					transition: color 0.5s;

					&:hover {
						border-bottom-color: currentColor;
					}
				}
			}
		}
	}
}

// fixed header
&.home,
&.de-bolder,
&.werken-bij-cultuur-page,
&.vacancy-vacancy-overview,
&.accommodation-search-book,
&.accommodation-category-detail {
	.header {
		@include media-breakpoint-up(md) {
			position: fixed;
			color: $white;

			.logo {
				&.white {
					display: block;
				}

				&.red {
					display: none;
				}

				&.red-dark {
					display: none;
				}
			}

			&.sticky {
				color: $red;

				.logo {
					&.white {
						display: none;
					}

					&.red {
						display: block;
					}
				}
			}
		}
	}
}

&.accommodation-book,
&.faq-overview,
&.faq-page {
	.header {
		position: relative !important;
	}
}

&.de-bolder {
	.header {
		position: absolute !important;
	}
}
