// container-two-columns
.container-two-columns {
  .container-holder {
    margin-bottom: -20px;
    > .column {
      margin-bottom: 20px;
    }
  }
}

// container-three-columns
.container-three-columns {
  .container-holder {
    margin-bottom: -20px;
    > .column {
      margin-bottom: 20px;
    }
  }
}

// container-four-columns
.container-four-columns {
  .container-holder {
    margin-bottom: -20px;
    > .column {
      margin-bottom: 20px;
    }
  }
}

// container-six-columns
.container-six-columns {
  .container-holder {
    margin-bottom: -20px;
    > .column {
      margin-bottom: 20px;
    }
  }
}
