// body
& {
	font-size: 18px;
	text-underline-offset: 0.3em;

	.btn,
	.form-control {
		font-size: 18px;
	}

	@include media-breakpoint-up(xl) {
		font-size: 21px;
	}
}

// headings
h1 {
	margin-bottom: 0;
	line-height: 0.9;
	font-weight: 400;
	letter-spacing: -1px;
	text-transform: uppercase;
	font-family: $font-family-tertiary;

	@include media-breakpoint-up(xl) {
		font-size: 84px;
	}
}

h2 {
	text-transform: uppercase;
	font-family: $font-family-secondary-black;

	@include media-breakpoint-up(xl) {
		font-size: 64px;
	}
}

h2,
h3 {
	line-height: 0.85;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// link-list
.link-list {
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;

	li {
		font-size: 24px;

		@include media-breakpoint-up(xl) {
			font-size: 26px;
		}

		font-weight: 700;
		border-bottom: 1px solid $white;

		&:last-of-type {
			border-bottom: 0;
		}

		a {
			position: relative;
			display: flex;
			align-items: center;
			padding: 1rem 0;
			text-decoration: none !important;

			&::after {
				content: '\f054';
				font-family: $font-awesome;
				margin-left: auto;
				color: inherit;
				font-size: 18px;
				font-weight: 400;
			}

			&:hover {
				padding-left: 5px;
			}
		}
	}

	&.large {
		li {
			@include media-breakpoint-up(xl) {
				font-size: 36px;

				a {
					padding: 1.5rem 0;
				}
			}

			&:first-of-type {
				a {
					padding-top: 0;
				}
			}
		}
	}
}

// searchbox
&.search {
	.searchbox {
		margin-bottom: 30px;
	}

	.search-term {
		margin-bottom: 30px;

		h3 {
			font-size: 21px;
			font-family: inherit;
		}
	}

	.search_alert {
		margin: 0 auto;
	}

	.card {
		.card-caption {
			.card-title {
				font-size: 24px;
			}
		}
	}
}

// modal
&.modal-open {
	padding-right: 0 !important;
	overflow: unset;
}

.modal {
	&.show {
		display: flex !important;
		padding-right: 0 !important;
	}

	.modal-dialog {
		margin: auto;
		padding: 20px;

		.modal-content {
			border: none;
			border-radius: 3px;
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
		}

		.modal-header {
			border: none;
			padding-bottom: 0;

			.modal-title {
				font-size: 24px;
				line-height: 1.2;
			}
		}

		.modal-body {
			font-size: 18px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// form
.form {
	font-size: 18px;

	.form-titleelement {
		margin-top: 45px;

		h3 {
			margin-bottom: 0.5rem;
			font-size: 21px;
		}
	}
}

// gdpr_cookie_plugin_body
.gdpr_cookie_plugin_body {
	@include media-breakpoint-down(md) {
		top: 15px !important;
		max-height: calc(100% - 30px);
		padding: 15px !important;
		overflow: auto;

		.gdpr_cookie_plugin_modal-footer {
			.gdpr_cookie_plugin_save {
				margin-bottom: 0 !important;
			}
		}
	}
}

// link-btn
div.link-btn {
	a {
		@extend .btn;
		@include media-breakpoint-up(xl) {
			min-width: 300px;
		}
		
		padding: 15px;
		background-color: $sand;
		border-color: $sand;
		color: $green !important;

		&:hover,
		&:focus {
			background-color: $white;
			border-color: $white;
			color: $black !important;
		}
	}
}
