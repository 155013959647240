// light
.bg-light {

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {

		&:hover {
			color: $sand;
		}
	}
}

// dark
.bg-dark {

	// a
	a:not(.btn):not(.card):not(.card-title-link):not(.card-btn) {

		&:hover {
			color: $sand;
		}
	}
}

// sand
.bg-sand {
	background-color: $sand;
	color: $red-dark;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li {
		color: $red-dark;
	}

	// a
	a:not(.btn) {
		color: $red-dark;
		text-decoration: underline;

		&:hover {
			color: $red;
		}
	}
}
