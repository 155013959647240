// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 400px;
	max-height: 640px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 85vh;
	min-height: 800px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 100vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	background: $black;

	@include media-breakpoint-down(sm) {
		display: none;
	}

	// owl-carousel
	.owl-carousel {
		&::before {
			display: none !important;
		}

		.item {
			&::before {
				content: "";
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($black, 0.1);
			}

			&::after {
				content: "";
				z-index: 2;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(180deg, rgba($black, 0) 63.61%, rgba($black, 0.7) 100%),
					linear-gradient(180deg, rgba($black, 0.4) 0%, rgba($black, 0) 19.24%),
					linear-gradient(0deg, rgba($black, 0), rgba($black, 0));
			}

			.owl-caption-holder {
				z-index: 3;

				.owl-container {
					.owl-caption {
						text-align: left;
						text-shadow: none;

						@include media-breakpoint-up(xl) {
							padding-right: 15%;
						}

						.owl-title,
						.owl-subtitle {
							margin-bottom: 0;
							line-height: 0.9;
							font-weight: 400;
							letter-spacing: -1px;
							text-transform: uppercase;
							font-family: $font-family-tertiary;
							@include font-size($h1-font-size);

							@include media-breakpoint-up(xl) {
								font-size: 84px;
							}
						}

						.owl-title {
							color: $red;
						}

						.owl-subtitle {
							color: $sand;
						}
					}
				}
			}
		}
	}

	// quick-links
	div.quick-links {
		z-index: 100;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 30px;

		@include media-breakpoint-up(md) {
			bottom: calc(10% + 60px);
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			@extend .container;

			li {
				flex-grow: 1;
				margin-right: 40px;

				@include media-breakpoint-up(xl) {
					font-size: 26px;
				}

				font-weight: 700;

				&:last-of-type {
					margin-right: 0;
				}

				a {
					position: relative;
					display: block;
					padding-bottom: 15px;
					border-bottom: 2px solid rgba($white, 0.2);
					color: $white;
					text-decoration: none;

					&::after {
						content: '\f054';
						position: absolute;
						top: 5px;
						right: 0;
						font-family: $font-awesome;
						color: inherit;
						font-size: $small-font-size;
						font-weight: 300;
						opacity: 0;
						transition: 0.5s;
					}

					&:hover {
						border-color: rgba($white, 1);

						&::after {
							opacity: 1;
						}
					}
				}

				&.active {
					a {
						border-color: rgba($white, 1);

						&::after {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

&.de-bolder {
	.eyecatcher {
		.owl-carousel {
			.item {
				.owl-caption-holder {
					.owl-container {
						.owl-caption {
							.owl-title {
								color: $sand;
							}

							.owl-subtitle {
								color: $red;
							}
						}
					}
				}
			}
		}
	}
}
