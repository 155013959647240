.page-block.food-menu-week {
	.week-item-days {
		display: flex;
		flex-direction: column;

		.week-item-day {
			display: none;

			&.active, &.show {
				display: inline-block;
			}
		}
	}

	.toggle-week-menu {
		display: inline-block;
		margin-bottom: 2.5rem;

		&.closed {
			.toggle-week-menu-hide {
				display: none;
			}
		}

		&.open {
			.toggle-week-menu-show {
				display: none;
			}
		}
	}
}
