// footer
.footer {
	color: $white;
	font-size: 16px;
	font-weight: 400;
	background: $white;

	@include media-breakpoint-down(sm) {
		text-align: center;
	}

	@include media-breakpoint-up(md) {
		font-size: 18px;
		background: url('/images/footer-bg-red.svg') no-repeat center bottom $red;
		background-size: 2560px;
	}

	a {
		color: $white;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	ul {
		margin-bottom: 0;
		padding-left: 0;
		list-style: none;
	}

	// footer-outro
	.footer-outro {
		padding-top: 8vh;
		padding-bottom: 8vh;
		line-height: 2;

		@include media-breakpoint-down(sm) {
			padding-bottom: 200px;
			background: url('/images/footer-bg-red.svg') no-repeat right bottom $red;
			background-size: 175%;
			margin-bottom: -1px;
		}

		@include media-breakpoint-down(xs) {
			padding-bottom: 150px;
		}

		// logo
		.logo {
			@include media-breakpoint-down(sm) {
				max-width: 170px;
				margin: 0 auto;
			}

			max-width: 200px;

			a {
				@extend .d-block;

				img {
					@extend .w-100;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.column {
				&.two {
					display: none;
				}
			}
		}

	}

	// footer-sitelinks
	.footer-sitelinks {
		display: flex;
		align-items: center;

		@include media-breakpoint-down(sm) {
			background: $white;
			padding-top: 30px;
		}

		@include media-breakpoint-up(md) {
			min-height: 200px;
		}

		@include media-breakpoint-up(lg) {
			min-height: 250px;
		}

		@include media-breakpoint-up(xl) {
			min-height: 270px;
		}

		.footer-link {
			.list {
				flex-direction: column;

				@include media-breakpoint-up(md) {
					align-items: flex-start;
				}

				.list-item {
					font-size: 15px;

					a {
						display: block;
						color: rgba($white, 0.5);
						padding: 0.5rem 0;

						@include media-breakpoint-down(sm) {
							color: $red;
						}
					}
				}
			}
		}

	}

	// footer-partners
	.footer-partners {
		@include media-breakpoint-down(sm) {
			background: $white;
			padding: 30px 0;
		}

		@include media-breakpoint-up(sm) {
			min-height: 65px;
		}

		.column {
			align-items: flex-end;

			@include media-breakpoint-down(sm) {
				align-items: center;
			}
		}

		.footer-text {
			ul {
				display: flex;
				align-items: center;

				li+li {
					margin-left: 30px;
				}
			}
		}
	}
}
