.collection {

	// grid
	&.grid {
		.grid-items {
			margin: 0 -20px -40px -20px;

			.item {
				margin: 0 0 40px 0;

				.card.default {
					border-radius: 10px;
					
					.card-image {
						.card-image-link {
							pointer-events: none;
						}
					}


					.card-body {
						padding: 20px;
					}

					.card-caption  {
						.card-title-link {
							pointer-events: none;
							cursor: text;

							.card-title {
								text-transform: uppercase;
								font-size: 21px;
								font-weight: 400 !important;
							}
						} 

						.card-subtitle {
							margin-top: 0;
							font-size: 0.875rem;
						}

						.card-description {
							font-size: 1rem;
						}
					}
					
				}	
			}
		}
	}

	// masonry
	&.masonry {
		.card-columns {
			column-count: 1;
			column-gap: 40px;

			.card {
				margin: 0 0 40px 0;
			}

			@include media-breakpoint-up(sm) {
				column-count: 1;
			}

			@include media-breakpoint-up(md) {
				column-count: 2;
			}

			@include media-breakpoint-up(lg) {
				column-count: 2;
			}

			@include media-breakpoint-up(xl) {
				column-count: 2;
			}
		}
	}

}
