// mini-sab-footer
.mini-sab-footer {
	margin-top: 8vh;
	margin-bottom: -60px;

	.container {
		position: relative;
		max-width: 1420px;

		.container-holder {
			z-index: 100;
			position: relative;
			min-height: 120px;
			padding: 20px 0;
			background: $white;

			@include media-breakpoint-up(md) {
				margin: 0;
				border-radius: 3px;
				box-shadow: 0 10px 20px rgba($black, 0.1);
			}

			@include media-breakpoint-up(xl) {
				padding: 25px 20px;
			}
		}
	}
}

.mini-sab-footer+.footer {
	padding-top: 60px;
}

// home
&.home,
&.de-bolder,
&.werken-bij-category-page {
	.mini-sab-footer {
		@include media-breakpoint-between(md, lg) {
			padding-top: 20px;
			background: $red;
		}

		@include media-breakpoint-down(lg) {
			margin-top: 0;
		}

		@include media-breakpoint-up(xl) {
			margin-top: -60px;
		}
	}
}
